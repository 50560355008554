
































































































































































































































import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import accountModule from '@/store/modules/accountModule';
import { getComponent } from '@/utils/helpers';
import { getConditionValueByValue } from '@/utils/workflowHelpers';
import workflowModule from '@/store/modules/workflowModule';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import { SHOW_ALERT } from '@/lib/constants';

@Component({
  components: {
    WorkflowInputs: () => getComponent('workflows/WorkflowInputs'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
  },
})
export default class UpdateWorkflow extends mixins(DataLoading) {
  autoCompleteOpened = false;

  sourceType = 'ALL';

  workflow = {
    conditions: [{
      condition: '',
      conditionValue: '' as any,
      valueModifier: 'GREATER_THAN',
      operationalModifier: '',
      value: '',
      howLong: '',
      editable: false,
      secondSectionEditable: false,
      showActions: false,
      connector: true,
      recurring: false,
      recurring_data: {},
    }],
    result: {
      to: 'Engineers',
      assignToType: 'ROLE',
      source: '',
    },
    id: uuidv4(),
    active: true,
  } as any;

  get filteredAssignToOptions() {
    if (this.workflow.result.assignToType === 'ROLE') {
      return [
        {
          text: 'Engineer',
          value: 'Engineers',
        },
        {
          text: 'Automation',
          value: 'Automation',
        },
        {
          text: 'Field Operator',
          value: 'FieldOperator',
        },
        {
          text: 'Operators',
          value: 'Operators',
        },
        {
          text: 'Optimizer',
          value: 'Optimizer',
        },
      ];
    }
    if (this.workflow.result.assignToType === 'TEAM') {
      return workflowModule.teams;
    }
    return accountModule.reassignmentList.map((i) => ({
      text: i.name,
      value: i.email,
    }));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get saveButtonDisabled() {
    return !this.workflow.result.to || !this.workflow.conditions.every(
      (c) => !c.editable && !c.secondSectionEditable && (!c.recurring ? true : c.value),
    );
  }

  async created() {
    if (this.$route.params.id) {
      this.workflow = JSON.parse(JSON.stringify(workflowModule.activeWorkflow));
      this.sourceType = this.workflow.result.source.length ? 'WELL' : 'ALL';
      this.workflow.result.to = this.workflow.result.to.value || this.workflow.result.to;
    }
    if (!this.enabledWells.length) {
      await assetsModule.getEnabledWells();
    }
    this.stopDataLoading();
  }

  addCondition() {
    this.workflow.conditions.push({
      condition: '',
      conditionValue: '',
      valueModifier: 'GREATER_THAN',
      value: '',
      howLong: '',
      editable: false,
      secondSectionEditable: false,
      showActions: false,
      connector: true,
    } as any);
  }

  capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getConditionHeading(condition) {
    if (!condition.condition && !condition.conditionValue) {
      return '';
    }
    const text = getConditionValueByValue(condition.conditionValue.value || condition.conditionValue)?.text || '';
    if (condition.secondSectionEditable) {
      return `${condition.condition.text}: ${text}`;
    }
    if (condition.condition.value === 'STATE_OF_WELL' && !condition.recurring) {
      return `${condition.condition.text}: ${text};
              <br> Recurring: ${condition.recurring ? 'Yes' : 'No'};`;
    }
    if (condition.condition.value === 'STATE_OF_WELL' && condition.recurring) {
      return `${condition.condition.text}: ${text};
              <br> Recurring: ${condition.recurring ? 'Yes' : 'No'};
              <br> Criteria: ${condition.value} times;
              <br> Out of: ${condition.howLong} previous days;
              `;
    }
	if (condition.operationalModifier // 👈 null and undefined check
	&& Object.keys(condition.operationalModifier).length === 0
	&& Object.getPrototypeOf(condition.operationalModifier) === Object.prototype) {
		condition.operationalModifier = undefined
	}

	if (condition.condition.text == null) {
		
		condition.condition.text = this.capitalizeFirstLetter(condition.condition.value.toLowerCase())
	}
    return `${condition.condition.text}: ${text};
              <br> Criteria: ${condition.operationalModifier || ''} ${condition.valueModifier.text || ''} ${(condition.valueModifier.value === 'DECREASES_AT'
      || condition.valueModifier.value === 'INCREASES_AT') ? `${condition.value}%` : (condition.value || '')};
              <br> For: ${condition.howLong} hour(s);`;
  }

  toggleConditionActions(condition) {
    Object.assign(condition, { showActions: !condition.showActions });
  }

  toggleConditionState(condition, index) {
    if (!condition.editable) {
      Object.assign(condition, {
        editable: true,
        condition: '',
        conditionValue: '',
        secondSectionEditable: false,
        showActions: false,
      });
    } else if (
      condition.operator === 'is'
      && condition.condition !== ''
      && condition.value !== ''
    ) {
      Object.assign(condition, { editable: false, showActions: false });
    } else if (Object.values(condition).every((x) => x !== '')) {
      Object.assign(condition, { editable: false, showActions: false });
    }
  }

  getHeading() {
    if (
      (!this.workflow.conditions[0].condition && !this.workflow.conditions[0].conditionValue)
      || !(this.workflow.result.to as any)?.text
    ) {
      return '';
    }
    let triggerText = '';
    this.workflow.conditions.forEach((condition, i) => {
      if ((condition.condition as any)?.value === 'STATE_OF_WELL') {
        const wellStateText = condition.recurring
          ? `current well state is <b>${(condition.conditionValue as any)?.text}</b> and has been ${(condition.conditionValue as any)?.text}
               <b>${condition.value || (condition?.recurring_data as any).times} time(s)</b> out of
               <b>${condition.howLong || (condition?.recurring_data as any).duration} ${(condition.recurring_data as any)?.durationValue}(s)</b>`
          : `current well state is <b>${(condition.conditionValue as any)?.text}</b>`;
        // eslint-disable-next-line
        triggerText += `${ wellStateText }`;
      } else {
        // eslint-disable-next-line
        if (!condition.conditionValue) return;
        triggerText += `<b>${condition.conditionValue.text}</b></b>
          ${(condition.valueModifier as any)?.text === 'Greater than' || (condition.valueModifier as any)?.text === 'Less than' ? 'is' : ''}
          <b>${(condition.valueModifier as any)?.text}</b>
          <b>${((condition.valueModifier as any)?.value === 'DECREASES_AT'
          || (condition.valueModifier as any)?.value === 'INCREASES_AT') ? `${condition.value}%` : (condition.value || '')}</b> for <b>${condition.howLong} hours</b>`;
      }
      // eslint-disable-next-line
      triggerText += `${ i === this.workflow.conditions.length - 1 ? '' : ((this.workflow.conditions[i + 1] || {}).connector ? ' and ' : ' or ') }`;
    });
    return `If ${triggerText.toLowerCase()} then assign a tasq to <b>${this.workflow.result.to.text || ''}</b>
      from ${this.workflow.result.source.length ? this.workflow.result.source : 'all wells'} source.`;
  }

  async saveChanges() {
    this.startDataLoading();
    this.workflow.createdby = accountModule.user.email;
    this.workflow.createddateUTC = new Date();
    if (this.$route.params.id) {
      await workflowModule.updateWorkflow(this.workflow);
    } else {
      await workflowModule.addWorkflow(this.workflow);
    }
    this.$eventBus.$emit(SHOW_ALERT, this.$route.params.nodeId ? 'Assignment updated' : 'New workflow rule created.');
    await this.$router.push({
      name: 'Workflow',
    });
    this.$emit('close-left-component');
    this.stopDataLoading();
  }

  @Watch('sourceType')
  onSourceTypeChanged() {
    this.workflow.result.source = this.sourceType === 'ALL' ? [] : this.workflow.result.source;
  }
}
