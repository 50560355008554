var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workflow && !_vm.dataLoading)?_c('div',{staticClass:"update-workflow text-textgray p-4",staticStyle:{"overflow-y":"scroll","height":"99vh"}},[_vm._m(0),_vm._l((_vm.workflow.conditions),function(condition,i){return _c('div',{key:i},[(i !== 0)?_c('div',{staticClass:"grid grid-flow-col justify-between text-cyan text-lg font-bold items-center my-4"},[_c('div',{staticClass:"grid grid-flow-col justify-start items-center"},[_c('t-toggle',{class:{
            'bg-cyan': true
          },model:{value:(condition.connector),callback:function ($$v) {_vm.$set(condition, "connector", $$v)},expression:"condition.connector"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(condition.connector ? 'and' : 'or')+" ")])],1),_c('button',{staticClass:"rounded py-1 text-white bg-tasqGrayDark text-lg w-24",on:{"click":function($event){return _vm.workflow.conditions.splice(i, 1)}}},[_vm._v(" Discard ")])]):_vm._e(),_c('div',{staticClass:"card-condition--fixed card-condition box-shadow-none"},[(condition.showActions && !condition.editable)?_c('div',[(i !== 0)?_c('div',{staticClass:"btn-icon btn-icon-remove btn-icon-remove--condition",on:{"click":function($event){return _vm.workflow.conditions.splice(i, 1)}}},[_c('span',{staticClass:"material-icons text-white"},[_vm._v(" clear ")]),_c('p',[_vm._v("Remove")])]):_vm._e(),_c('div',{staticClass:"btn-icon btn-icon-edit btn-icon-edit--condition",style:(("" + (i === 0 ? 'left: 0' : ''))),on:{"click":function($event){return _vm.toggleConditionState(condition, i)}}},[_c('span',{staticClass:"material-icons text-white"},[_vm._v(" edit ")]),_c('p',[_vm._v("Edit")])])]):_vm._e(),(_vm.getConditionHeading(condition))?_c('v-touch',{attrs:{"swipe-options":{
          direction: 'horizontal'
        }},on:{"swiperight":function($event){return _vm.toggleConditionActions(condition)},"swipeleft":function($event){return _vm.toggleConditionActions(condition)}}},[_c('div',{staticClass:"card-condition-title mb-3",style:(("" + (i === 0 ? 'left: 70px' : ''))),domProps:{"innerHTML":_vm._s(_vm.getConditionHeading(condition))}})]):_vm._e()],1),_c('workflow-inputs',{ref:"workflowInputs",refInFor:true,attrs:{"workflow":_vm.workflow,"condition-index":i},on:{"close":function($event){_vm.editionCondition = false}}})],1)}),_c('div',{staticClass:"border-b-2 border-textgray pb-4 mb-3"},[_c('button',{staticClass:"grid grid-flow-col items-center gap-x-2 btn-default border border-textgray rounded\n          px-3 py-2",class:{
        'hidden': !(_vm.workflow.conditions[0] || {}).condition,
      },on:{"click":_vm.addCondition}},[_c('span',{staticClass:"material-icons"},[_vm._v(" add_circle_outline ")]),_vm._v(" Add condition ")])]),_vm._m(1),_c('div',{staticClass:"text-center mt-2"},_vm._l((['ROLE', 'TEAM', 'INDIVIDUAL']),function(i){return _c('div',{key:i},[_c('button',{staticClass:"h-10 w-full rounded-lg text-sm text-textgray border mb-4 border-textgray capitalize",class:{
          'bg-tasqBlue text-white border-tasqBlue': _vm.workflow.result.assignToType === i
        },on:{"click":function($event){_vm.workflow.result.assignToType = i}}},[_vm._v(" "+_vm._s(i.toLocaleLowerCase())+" ")])])}),0),_c('div',{staticClass:"border-b-2 border-textgray pb-4 mb-3"},[_c('t-radio-group',{staticClass:"radio--full-width mt-0",attrs:{"options":_vm.filteredAssignToOptions},model:{value:(_vm.workflow.result.to),callback:function ($$v) {_vm.$set(_vm.workflow.result, "to", $$v)},expression:"workflow.result.to"}})],1),_vm._m(2),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"h-10 w-full rounded-lg text-sm text-textgray border mt-4 border-textgray capitalize",class:{
        'bg-tasqBlue text-white border-tasqBlue': _vm.sourceType === 'ALL'
      },on:{"click":function($event){_vm.sourceType = 'ALL'}}},[_vm._v(" All wells ")]),_c('button',{staticClass:"h-10 w-full rounded-lg text-sm text-textgray border mt-4 border-textgray capitalize",class:{
        'bg-tasqBlue text-white border-tasqBlue': _vm.sourceType === 'WELL'
      },on:{"click":function($event){_vm.sourceType = 'WELL'}}},[_vm._v(" Choose well ")]),(_vm.sourceType === 'WELL')?_c('div',{staticClass:"mt-4 mb-6"},[_c('t-rich-select',{attrs:{"options":_vm.enabledWells,"multiple":"","label":"Choose well"},model:{value:(_vm.workflow.result.source),callback:function ($$v) {_vm.$set(_vm.workflow.result, "source", $$v)},expression:"workflow.result.source"}})],1):_vm._e(),_c('p',{staticClass:"text-left font-weight-bold color--lightgreen mt-6",domProps:{"innerHTML":_vm._s(_vm.getHeading())}})]),_c('div',[_c('button',{staticClass:"h-10 w-full rounded-lg text-sm text-textgray border mt-4 border-textgray capitalize",attrs:{"disabled":_vm.saveButtonDisabled},on:{"click":_vm.saveChanges}},[_vm._v(" Confirm ")]),_c('button',{staticClass:"h-10 w-full rounded-lg text-sm text-textgray border mt-4 border-textgray",on:{"click":function($event){return _vm.$emit('close-left-component')}}},[_vm._v(" Cancel ")])])],2):_c('div',[_c('spinner-loader')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"mb-3 text-lg font-bold"},[_vm._v(" Data feeds: ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-0"},[_c('p',{staticClass:"text-lg font-bold"},[_vm._v(" Create tasq to: ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 font-bold text-lg"},[_vm._v(" From what source: ")])])}]

export { render, staticRenderFns }